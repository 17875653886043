.login {
    display: flex;
    border: 1px solid black;
    border-radius: 5px;
    max-width: 1000px;
    height: 480px;
    width: 90%;
    margin: auto;
    background-color: white;
}

.loginHeader {
    height: 40px;
    line-height: 40px;
    background-color: #eee;
    border-bottom: 1px solid #ccc;
    width: 100%;
    border-top-left-radius: 5px;
    margin-bottom: 10px;
}

.loginForm {
    width: 300px;
    border-right: 1px solid black;
    text-align: center;
    flex-shrink: 0;
    color: #444;
}

.loginImage {
    width: 100%;
    max-width: calc(90vw - 300px);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    flex-shrink: 1;
    object-fit: cover;
}

@media only screen and (max-width: 650px) {
    .loginImage {
        display: none;
    }
    .loginForm {
        width: 100%;
        border-right: initial;
    }
}

.switchSignIn {
    cursor: pointer;
    color: rgb(60, 117, 150);
}

.switchSignIn:hover {
    cursor: pointer;
    color: rgb(46, 89, 114);
}
