.dualMeetInfoPage {
    padding-top: 30px;
    text-align: center;
}

.dualMeetInfoTable {
    max-width: 1000px;
    width: 90vw;
    margin: 15px auto;
}

.dualMeetInfoEditBoutButton {
    border: 2px solid green;
    border-radius: 5px;
    background-color: rgb(100, 255, 160);
    padding: 0 12px;
    font-size: 18px;
}

.selectedBout {
    max-width: 500px;
    width: 90%;
    height: 300px;
    overflow: hidden;
    margin: auto;
    flex-shrink: 0;
}

@media only screen and (max-width: 600px) {
    .selectedBout {
        width: 100%;
        margin: 0;
    }
}

.avoidWrap {
    display: inline-block;
}

.scoreBox {
    width: 50px;
    height: 50px;
    border: 2px solid white;
    border-radius: 10px;
    line-height: 45px;
    font-size: 24px;
    font-weight: 500;
    margin-top: auto;
}

.liveNowBoutsList {
    display: flex;
    flex-direction: column;
}

.boutViewerList {
    text-align: center;
    overflow-y: auto;
    width: 370px;
    flex-shrink: 0;
    transition: all 0.5s ease;
}

.boutViewerToggleButton {
    padding: 5px;
    cursor: pointer;
    background-color: #666;
    border-color: #555;
    transition: all 0.1s ease;
}

.boutViewerToggleButton:hover {
    background-color: #777;
}

.boutInViewerList {
    display: flex;
    padding: 10px;
    color: #DDD;
    position: relative;
    cursor: pointer;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.index0 {
    opacity: 100%;
}

.refereeLine {
    display: flex;
    align-items: center;
}
